import { useUnit } from 'effector-react'
import isNull from 'lodash/isNull'

import { useCallback, useMemo, useState, useEffect, useContext, useSelector } from 'hooks'

import { goTo, urlTo } from 'helpers/router.js'

import { $isTransferProgressLoading } from 'app/effector/isa-transfer'

import { ScrollSaverContext } from 'app/containers/ScrollSaver'

import { usePendingOrders } from 'app/pages/Dashboard/Goals/DIY/hooks'
import { usePeriod } from 'app/pages/Securities/hooks'

import { useAltusProgressData } from './useAltusProgressData.ts'
import { useDiyPortfolioTabs } from './useDiyPortfolioTabs'
import { usePortfolioActions } from './usePortfolioActions'
import { usePortfolioData } from './usePortfolioData.js'
import { usePortfolioRestrictions } from './usePortfolioRestrictions'
import { useTransferRestriction } from './useTransferRestriction'

import { types as clientTypes } from 'constants/client'
import { regulatoryTypes } from 'constants/portfolio'

const usePortfolio = (portfolioId, location, routes) => {
  const [autoinvestAfterSellModalOpen, setAutoinvestAfterSellModalOpen] = useState(false)
  const { orders } = usePendingOrders()
  const {
    isSecuritiesLoading,
    isHistoryLoading,
    portfolio,
    messages,
    securities,
    chartData,
    pointsOfInterest,
    showFinishSavingsPlanBanner,
    quickStart,
    showFirstTopupBanner,
    showLumpSumBanner,
    daysBeforePayment,
  } = usePortfolioData({ portfolioId, orders })
  const portfolioOrders = useMemo(() => orders.getByPortfolioId(portfolioId), [portfolioId, orders])
  const { selectedPeriod, handlePeriodSelect, periodPriceData, contributions } = usePeriod(portfolio)
  const {
    isRebalanceLoading,
    isRebalanceRestricted,
    rebalanceRestrictionType,
    handleRebalanceRestrictionModalClose,
    actions,
  } = usePortfolioActions(portfolio)
  const holdingsChanged = useMemo(() => isNull(location?.query?.holdingsChanged), [location?.query?.holdingsChanged])
  const { altusProgressData, handleDismissAltusPanel } = useAltusProgressData(portfolio?.id)
  const { isTransferRestrictionModalVisible, handleTransferRestrictionModalOpen, handleTransferRestrictionModalClose } =
    useTransferRestriction()
  const clientType = useSelector((state) => state.client.type)

  const [isTransferProgressLoading] = useUnit([$isTransferProgressLoading])

  const { resetAllScrollPositions } = useContext(ScrollSaverContext)

  const { activeChart, setChartTab, setPeriodTab } = useDiyPortfolioTabs({
    portfolioId: portfolio?.id,
    location,
    routes,
    selectedPeriod,
    handlePeriodSelect,
  })

  useEffect(() => {
    if (location?.query?.autoinvestAfterSell === undefined) {
      setAutoinvestAfterSellModalOpen(false)
      return
    }
    setAutoinvestAfterSellModalOpen(true)
  }, [location?.query?.autoinvestAfterSell])

  useEffect(() => {
    if (portfolio?.weights_setup_needed) {
      goTo(urlTo('dashboard.portfolio', { id: portfolio?.id }, { holdingsChanged: null }))
    }
  }, [portfolio?.weights_setup_needed, portfolio?.id])

  const handleBack = useCallback(() => {
    goTo(urlTo('dashboard'))
  }, [])
  const handleAddSecurities = useCallback(() => {
    resetAllScrollPositions()

    goTo(urlTo('securities', null, { portfolioId, fromPortfolio: true }))
  }, [resetAllScrollPositions, portfolioId])

  const handleSelectSecurity = useCallback(
    (securityId) => {
      goTo(urlTo('portfolio.security', { id: portfolioId, securityId }, { chart: activeChart, period: selectedPeriod }))
    },
    [portfolioId, activeChart, selectedPeriod],
  )
  const handleBuySellRaw = useCallback(() => {
    if (portfolio?.weights_setup_needed) {
      goTo(urlTo(`dashboard.portfolio`, { id: portfolioId }, { holdingsChanged: null }))
      return
    }

    goTo(urlTo('portfolio.buy', { id: portfolioId }))
  }, [portfolio?.weights_setup_needed, portfolioId])

  const { restrictionModalOpen, restrictionModalType, handleRestrictionModalClose, handleBuySell, portfolioActions } =
    usePortfolioRestrictions({
      portfoliosSecurities: securities,
      portfolioActions: actions,
      portfolioOrders,
      handleBuySellRaw,
    })

  const regulatoryTypeText = useMemo(
    () =>
      ({
        [regulatoryTypes.GIA]: clientType === clientTypes.BUSINESS ? 'Business' : 'General',
        [regulatoryTypes.ISA]: 'ISA',
        [regulatoryTypes.SIPP]: 'Personal Pension',
      })[portfolio.regulatory_type],
    [clientType, portfolio.regulatory_type],
  )

  return {
    isSecuritiesLoading,
    isHistoryLoading,
    isRebalanceLoading,
    portfolio,
    messages,
    portfolioActions,
    portfolioOrders,
    securities,
    chartData,
    pointsOfInterest,
    selectedPeriod,
    handlePeriodSelect: setPeriodTab,
    periodPriceData,
    contributions,
    restrictionModalOpen,
    restrictionModalType,
    isRebalanceRestricted,
    rebalanceRestrictionType,
    autoinvestAfterSellModalOpen,
    holdingsChanged,
    activeChart,
    handleRebalanceRestrictionModalClose,
    handleRestrictionModalClose,
    handleBack,
    handleAddSecurities,
    handleSelectSecurity,
    handleBuySell,
    setChartTab,
    showFinishSavingsPlanBanner,
    quickStart,
    showFirstTopupBanner,
    showLumpSumBanner,
    daysBeforePayment,
    altusProgressData,
    isTransferRestrictionModalVisible,
    handleTransferRestrictionModalOpen,
    handleTransferRestrictionModalClose,
    regulatoryTypeText,
    handleDismissAltusPanel,
    isTransferProgressLoading,
  }
}

export { usePortfolio }
