import React from 'react'

import classNames from 'classnames/dedupe'

import { useMemo } from 'hooks/'

import { palette } from 'helpers/palette/'

import Icon from 'components/_old/Icon/Icon.jsx'

import './Checkbox.css'

type CheckboxType = 'checkbox' | 'radio'

type CheckboxProps = React.HTMLProps<HTMLInputElement> & {
  CustomInput?: React.FC<React.HTMLProps<HTMLInputElement>>
  className?: string
  realInputClassName?: string
  type: CheckboxType
  checked: boolean
  valid?: boolean
  onChange?: (...args: unknown[]) => void
}

const Checkbox = ({
  CustomInput,
  className,
  realInputClassName,
  type,
  checked,
  valid = true,
  required,
  onChange,
  ...restInputProps
}: CheckboxProps): React.ReactElement => {
  const classes = classNames(className, 'Checkbox', {
    [`Checkbox_${type}`]: type,
    [`Checkbox_invalid`]: !valid,
  })

  const realInputClasses = classNames(realInputClassName, 'Checkbox-RealInput')
  const handleChange = useMemo(() => (onChange ? onChange.bind(this, !checked) : null), [onChange, checked])

  return (
    <div className={classes}>
      {CustomInput ? (
        <CustomInput
          className={realInputClasses}
          onChange={handleChange}
          checked={checked}
          data-checked={checked}
          required={required}
          {...restInputProps}
        />
      ) : (
        <input
          className={realInputClasses}
          type={type}
          onChange={handleChange}
          checked={checked}
          data-checked={checked}
          required={required}
          {...restInputProps}
        />
      )}
      <div className="Checkbox-Input" role="presentation">
        <Icon className="Checkbox-Icon" size={14} type="check-16" color={palette['content-on-color-default']} />
      </div>
    </div>
  )
}

export { Checkbox }
