import { useUnit } from 'effector-react'
import isNil from 'lodash/isNil'
import isNull from 'lodash/isNull'

import { useActions, useSelector, useState } from 'hooks'

import { trackEvent } from 'helpers/analytics'
import { processError } from 'helpers/errors'
import { isFieldsDisabledByRuleset, isDisabledByRuleset } from 'helpers/field.js'
import { prepareName } from 'helpers/forms'
import { goTo, urlTo } from 'helpers/router'
import { combineErrors, backendErrorsToObj, emailRules, nameRules, phoneRules } from 'helpers/validation.js'

import { $owner, changeContactField, updateOrCreateContactFx } from 'app/effector/contacts'
import { Contact } from 'app/effector/contacts/models'
import { $dictsStore } from 'app/effector/dicts'

import {
  updateOrCreate as updateOrCreateClientActionCreator,
  resetError as resetClientErrorActionCreator,
  setValid as setClientValidActionCreator,
} from 'app/redux/actions/client'
import { showSuccessToast, showSupportModal as showSupportModalActionCreator } from 'app/redux/actions/ui'
import { ApiError } from 'app/redux/models/errors'
import { isClientApproved, isClientNewOrNotCompleted } from 'app/redux/selectors'

import { usePromocode, type UsePromocodeProps } from './usePromocode'

import { types as clientTypes, wealthSources as clientWealthSources } from 'constants/client'

type UsePersonalInfoReturnProps = {
  client: Record<string, any>
  isBusiness: boolean
  owner: Contact
  dicts: Record<string, any>
  fullAddress?: string
  ownerEmail?: string
  genderName: string
  clientNewOrNotCompleted: boolean
  clientApproved: boolean
  clientValidation: Record<string, { rules: boolean[]; errors: string[] }>
  financesValidation: Record<string, { rules: boolean[]; errors: string[] }>
  isPersonalFieldsDisabled: boolean
  isFinancesFieldsDisabled: boolean
  isEmailFieldDisabled: boolean
  handleSubmit: () => Promise<void>
  handleBack: () => void
  handleAddressModalClose: () => void
  handleEmailChange: (_event: React.ChangeEvent<HTMLInputElement>, value: string) => void
  handleFirstNameChange: (_event: React.ChangeEvent<HTMLInputElement>, value: string) => void
  handleMiddleNameChange: (_event: React.ChangeEvent<HTMLInputElement>, value: string) => void
  handleLastNameChange: (_event: React.ChangeEvent<HTMLInputElement>, value: string) => void
  handleBirthdayChange: (_event: React.ChangeEvent<HTMLInputElement>, value: string) => void
  handleGenderChange: (value: string) => void
  handlePhoneChange: (_event: React.ChangeEvent<HTMLInputElement>, value: string) => void
  showSupportModal: () => void
} & UsePromocodeProps

const usePersonalInfo = (): UsePersonalInfoReturnProps => {
  const usePromocodeData = usePromocode()
  const [error, setError] = useState(null)

  const [showSupportModal, updateOrCreateClient, resetClientError, setClientValid] = useActions([
    showSupportModalActionCreator,
    updateOrCreateClientActionCreator,
    resetClientErrorActionCreator,
    setClientValidActionCreator,
  ])

  const client = useSelector((state) => state.client)
  const clientNewOrNotCompleted = useSelector(isClientNewOrNotCompleted)
  const clientApproved = useSelector(isClientApproved)
  const isBusiness = client.type === clientTypes.BUSINESS

  const { dicts } = useUnit($dictsStore)
  const owner = useUnit($owner) ?? new Contact()
  const [currentAddress] = owner.addresses.getCurrentAddress()
  const fullAddress = currentAddress?.getAddressString(dicts.countries)
  const ownerEmail = isNil(owner.email) ? client.email : owner.email
  const {
    wealth_source: wealthSource,
    other_wealth_source: otherWealthSource,
    employment_status: employmentStatus,
    error: clientError,
  } = client

  const clientValidation = clientNewOrNotCompleted
    ? combineErrors({ email: emailRules(ownerEmail) }, backendErrorsToObj(error))
    : combineErrors(
        {
          first_name: nameRules(owner.first_name, 'First name'),
          middle_name: nameRules(owner.middle_name, 'Middle name', false),
          last_name: nameRules(owner.last_name, 'Last name'),
          phone: phoneRules(owner.phone),
          email: emailRules(ownerEmail),
        },
        backendErrorsToObj(error),
      )

  const financesValidation =
    clientNewOrNotCompleted || isBusiness
      ? {}
      : combineErrors(
          {
            wealth_source: {
              rules: [!isNull(wealthSource)],
              errors: ['Source of wealth must be chosen'],
            },
            gift_origin: {
              rules: [
                wealthSource !== clientWealthSources.GIFT || otherWealthSource,
                wealthSource !== clientWealthSources.GIFT || (otherWealthSource || '').length <= 50,
              ],
              errors: ['Gift origin must be chosen', 'Gift origin must be less than 50 characters'],
            },
            other_wealth_source: {
              rules: [
                wealthSource !== clientWealthSources.OTHER || otherWealthSource,
                wealthSource !== clientWealthSources.OTHER || (otherWealthSource || '').length <= 50,
              ],
              errors: ['Source of wealth must be chosen', 'Source of wealth must be less than 50 characters'],
            },
            employment_status: {
              rules: [!isNull(employmentStatus)],
              errors: ['Employment status must be chosen'],
            },
          },
          backendErrorsToObj(clientError),
        )

  const changeOwnerField = (field, value): void => {
    changeContactField({ field: { [field]: value }, id: owner.id })
    setError(null)
  }

  const handleEmailChange = (_event, value): void => {
    changeOwnerField('email', value)
  }
  const handleFirstNameChange = (_event, value): void => {
    changeOwnerField('first_name', value)
  }
  const handleMiddleNameChange = (_event, value): void => {
    changeOwnerField('middle_name', value)
  }
  const handleLastNameChange = (_event, value): void => {
    changeOwnerField('last_name', value)
  }
  const handlePhoneChange = (_event, value): void => {
    changeOwnerField('phone', value.replace(/[ \-()]/g, ''))
  }

  const handleUpdateContact = async (): Promise<object | ApiError> => {
    if (owner.first_name) {
      changeOwnerField('first_name', prepareName(owner.first_name))
    }
    if (owner.middle_name) {
      changeOwnerField('middle_name', prepareName(owner.middle_name))
    }
    if (owner.last_name) {
      changeOwnerField('last_name', prepareName(owner.last_name))
    }

    const fields = (() => {
      if (clientNewOrNotCompleted) {
        return ['email']
      }

      let fields = []

      if (!owner.lock_personal_info) {
        fields = [...fields, 'first_name', 'middle_name', 'last_name']

        if (!owner.phone?.includes('*')) {
          fields = [...fields, 'phone']
        }
      }

      if (!owner.email_verified) {
        fields = [...fields, 'email']
      }

      return fields
    })()

    try {
      const result = await updateOrCreateContactFx({
        data: owner,
        id: owner.id,
        keys: fields,
      })

      if (result instanceof ApiError) {
        throw result
      }
      return result
    } catch (error) {
      setError(error)

      processError({
        error,
      })

      return error
    }
  }

  const handleUpdateClient = async (): Promise<object | ApiError> => {
    const fields = ['wealth_source', 'other_wealth_source', 'employment_status']
    const filteredFields = fields.filter(
      (field) =>
        !isDisabledByRuleset({
          field,
          rules: dicts.client_lock_rules,
          model: client,
        }),
    )
    try {
      const stateAfterClientUpdate = await updateOrCreateClient(filteredFields)

      if (stateAfterClientUpdate.client.error) {
        throw stateAfterClientUpdate.client.error
      }

      return stateAfterClientUpdate
    } catch (error) {
      processError({
        error,
        resets: [resetClientError],
      })

      setClientValid()

      return error
    }
  }

  const handleSubmit = async (): Promise<void> => {
    const promises: Array<Promise<any>> = [handleUpdateContact()]

    if (!isBusiness) {
      promises.push(handleUpdateClient())
    }

    const results = await Promise.all(promises)

    if (!results.some((result) => result instanceof ApiError)) {
      showSuccessToast('Profile successfully updated')
    }

    trackEvent({ category: 'Settings', action: 'Basic changed' })
  }

  const handleBack = (): void => {
    goTo(urlTo('dashboard.user-profile'))
  }

  const handleAddressModalClose = (): void => {
    goTo(urlTo('dashboard.user-profile.personal-info'), { replace: true, scrollToTop: false })
  }

  const isPersonalFieldsDisabled = isFieldsDisabledByRuleset({
    fields: ['first_name', 'last_name', 'phone'],
    rules: dicts.contact_lock_rules,
    model: owner,
  })

  const isFinancesFieldsDisabled = isFieldsDisabledByRuleset({
    fields: ['wealth_source', 'other_wealth_source', 'employment_status'],
    rules: dicts.client_lock_rules,
    model: client,
  })

  const isEmailFieldDisabled = isFieldsDisabledByRuleset({
    fields: ['email'],
    rules: dicts.contact_lock_rules,
    model: owner,
  })

  return {
    client,
    isBusiness,
    owner,
    dicts,
    fullAddress,
    ownerEmail,
    clientValidation,
    financesValidation,
    clientNewOrNotCompleted,
    clientApproved,
    isPersonalFieldsDisabled,
    isFinancesFieldsDisabled,
    isEmailFieldDisabled,
    handleSubmit,
    handleBack,
    handleAddressModalClose,
    handleEmailChange,
    handleFirstNameChange,
    handleMiddleNameChange,
    handleLastNameChange,
    handlePhoneChange,
    showSupportModal,
    ...usePromocodeData,
  }
}

export { usePersonalInfo }
