import React from 'react'

import { Typography } from 'components/atoms/Typography'

import './ConnectedList.css'

type ConnectedListProps = {
  items: React.ReactNode[]
}

const ConnectedList = ({ items }: ConnectedListProps): JSX.Element => {
  return (
    <ol role="list" className="ConnectedList">
      {items.map((item, index) => (
        <li key={index} className="ConnectedList-Item">
          <span className="ConnectedList-Number" aria-hidden="true">
            <Typography size={20} lineHeight="small" tag="span" color="inherit">
              {index + 1}
            </Typography>
          </span>
          <Typography tag="p" lineHeight="small" data-test-id="listItem">
            {item}
          </Typography>
        </li>
      ))}
    </ol>
  )
}

export default ConnectedList
